import { WEB_URL } from "../../../Config";
import { useTranslation } from 'react-i18next';
import Cookies from 'universal-cookie';
import { Fragment,useState } from "react";

const ChooseLanguage = ({allWorldRegionsWithLangs}) => {

    const cookies = new Cookies();
    const {t,i18n} = useTranslation();

    const [openLangs,SetOpenLangs] = useState(false);

    const SetLang = (lang) => {

		cookies.set("aup_lang",lang,{path :"/",sameSite:"none",secure:true});
		i18n.changeLanguage(lang);

        SetOpenLangs(false);
	}

    return(
        <div className="langs">
									
            <img 
                src={WEB_URL + "/public/Images/Flags/" + i18n.language + ".jpg"} 
                onClick = {() => SetOpenLangs(true)}
            />
            
            <div className={"all-langs" + (openLangs ? " open" : "")}>

                <button 
                    onClick={() => SetOpenLangs(false)}
                    className="close"
                >x</button>

                <div className="container">

                    {allWorldRegionsWithLangs && allWorldRegionsWithLangs.map((item) => (

                        <Fragment key={item.worldRegionID}>
                            <h3><span>{item.title}</span></h3>
                            {item.languages && item.languages.length > 0 ?
                                <ul>
                                    {item.languages.map((it) => {
                                        if(it.showOnWeb)
                                            return(
                                                <li key={it.languageID} onClick={() => SetLang(it.suffix)}>
                                                    <img src={WEB_URL + "/public/Images/Flags/" + it.file} />
                                                    {it.langTitle}
                                                </li>
                                            )
                                        else
                                            return ""
                                    })}
                                </ul>
                            :null}
                        </Fragment>

                    ))}
                </div>

            </div>
        </div>
    )
}

export default ChooseLanguage;