/*
    Formulář pro přihlášení se do adminu
*/
import React,{useEffect} from 'react';
import { Link } from "react-router-dom";
import logo from '../../../Public/Images/logo.svg';
import university from '../../../Public/Images/university.webp';
import astralProjection from '../../../Public/Images/astral_projection.webp';
import { useTranslation } from 'react-i18next';
import Loading from '../../../GlobalComponents/Loading';
import Error from '../../../GlobalComponents/Error';

import { useAuth } from '../Library/useAuth';
import { useNavigate, Outlet } from "react-router-dom";
import {useQuery } from '@apollo/client';

import { GET_CUSTOMER } from '../Queries/signpost';
import Helper from '../../../Library/helper';
import Cookies from 'universal-cookie';

const Signpost = () => {

    const {t,i18n} = useTranslation();

    const {IsSignedIn,loading} = useAuth();
	const navigate = useNavigate();

    var {data,loading:dataLoading,error} = useQuery(GET_CUSTOMER,{
        fetchPolicy: 'network-only',
    });
	
	useEffect(() =>{
        if(!IsSignedIn() && !loading)navigate("/");
    },[IsSignedIn,loading])

    useEffect(() => {
        if(data && data.customerContext)
        {
            const cookies = new Cookies();
            const cookiesLang = cookies.get("aup_lang");

            if(!cookiesLang || data.customerContext.domain.lang != cookiesLang)
            {
                i18n.changeLanguage(data.customerContext.domain.lang);
                cookies.set("aup_lang",data.customerContext.domain.lang,{path :"/",sameSite:"none",secure:true});
            }
        }
    },[data])

    if(error)
    {
       const helper = new Helper();
       error = helper.getApolloErrorText(error);
    }

    return (

        <>
			{IsSignedIn() && !loading ?	

                <div id="auth-panel">
                    <div className="logo">
                        <img alt={t('AstralProjection')} src={logo} />
                    </div>
                    <div className="card">

                        <div className="card-header"><span>{t('AstralProjection')}</span></div>
                        <div className="card-body signpost">

                            {error ?
                                <Error text = {error} />
                            :
                                (dataLoading ?
                                    <div className="card-loading-content">
                                        <Loading />
                                    </div>
                                :   
                                    <div className="row">

                                        <div className="col-6 col-xs-6">
                                            <img alt="university" src={university} />
                                        </div>
                                        <div className="col-6 col-xs-6">
                                            <Link to={(data.customerContext.lastViewedMembership && data.customerContext.lastViewedMembership.membershipDurationID ?
                                                '/audio-recordings/' + data.customerContext.lastViewedMembership.membershipDurationID + "-" + data.customerContext.lastViewedMembership.niceName
                                            :
                                                '/audio-recordings/' + data.customerContext.firstMembership.membershipDurationID + "-" + data.customerContext.firstMembership.niceName
                                            )}>
                                                <img alt="astral projection" src={astralProjection} />
                                            </Link>
                                        </div>

                                    </div>
                                ) 
                            }
                        </div>
                    </div>
                </div>
            :null}
        </>
    );
  
}

export default Signpost;
