import gql from 'graphql-tag';

export const LOGIN_USER = gql`
  mutation login($email: String!,$password: String!,$lang: String) {
    login(email: $email,password: $password,lang: $lang){
        loginToken
        customer{
            name
            surname
            confirm
        }
    }
  }
`;