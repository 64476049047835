import React,{useEffect, useState} from 'react';
import { useTranslation } from 'react-i18next';
import Loading from '../../../GlobalComponents/Loading';
import Error from '../../../GlobalComponents/Error';
import Success from '../../../GlobalComponents/Success';
import {useQuery} from '@apollo/client';
import {GET_CUSTOMER_FOR_PAYMENT} from '../../Customer/Queries/customer';
import {CardElement} from '@stripe/react-stripe-js';
import {UsePayment} from '../Library/payment';
import { SERVER_URL } from '../../../Config';
import Helper from '../../../Library/helper';

const CARD_ELEMENT_OPTIONS = {
    style: {
      base: {
        color: "#ffffff",
        fontFamily: 'Barlow',
        fontSmoothing: "antialiased",
        fontSize: "18px",
        "::placeholder": {
          color: "#7d7d7d",
        },
        ":focus": {
          color: "#ffffff",
        },
        ':-webkit-autofill': {
            color: "#ffffff",
        },
        backgroundColor:"#121212",
        iconColor: "#ffffff",
      },
      invalid: {
        color: "#f36565",
        iconColor: "#f36565",
      },
    },
    hidePostalCode:true
};

const Payment = ({membership}) => {

    const helper = new Helper();

    const {t,i18n} = useTranslation();
    const [err, paymentError, succesPayment, formData, load, FormHandle, InitFormData,SetPaymentError, DoPayment] = UsePayment();
    const [allCountries,SetAllCountries] = useState([]);
    const [currencyCode, SetCurrencyCode] = useState("");
    const [hasStoredPaymentMethod,SetHasStoredPaymentMethod] = useState(false);

    const {data,loading,error} = useQuery(GET_CUSTOMER_FOR_PAYMENT,{
        fetchPolicy: 'network-only',
        onCompleted:(d)=>{

            SetAllCountries(d.allCountries);
            SetCurrencyCode(d.customerContext.domain.currencyCode);
            SetHasStoredPaymentMethod(d.customerContext.hasStoredPaymentMethod);

            var custData = {
                customerID:             d.customerContext.customerID,
                domainID:               d.customerContext.domainID,
                email:                  d.customerContext.email,
                name:                   d.customerContext.name || "",
                surname:                d.customerContext.surname || "",
                street:                 d.customerContext.street || "",
                city:                   d.customerContext.city || "",
                zip:                    d.customerContext.zip || "",
                countryID:              d.customerContext.countryID,
                tel:                    d.customerContext.tel || "",
                domain:                 d.customerContext.domain.domainName,
                membershipDurationID:   membership.membershipDurationID
            }

            if(d.customerContext.countryID == 239)
            {
                const splitCity = d.customerContext.city.split(", ");
                custData.city   = splitCity[0];
                custData.state  = splitCity[1];
            }
  
            InitFormData(custData,membership,d.customerContext.domain.currencyTitle); 
        }
    });

    const RepeatPayment = () => {
        SetPaymentError("");
        SetHasStoredPaymentMethod(false);
    }

    var errorMessage = "";
    if(error || err){
        errorMessage = (error || err);
    }

    var loadingData = false;
    if(loading || load){
        loadingData = true;
    }

    var additionalPrice = 0;
    if(membership)
    {
        if(membership.additionalServices)
        {
            for(let ads of membership.additionalServices)
            {
                additionalPrice += ads.price; 
            }
        }
    }

    console.log(i18n.language);

    return(
        <div>

            {loadingData ?
                <Loading />
            :
                (paymentError ?

                    <>
                        <Error text={paymentError} />
                        <button onClick={() => RepeatPayment()} className="btn btn-primary btn-large btn-block">{t("RepeatPayment")}</button>
                    </>

                :
                    (succesPayment ?
                        <Success text={t("ThanksPaymentIsOkMembershipActive")} />
                    :
                        <div id="payment-form">

                            <div className="membership">
                                <div>
                                    <img alt={membership.name} src={SERVER_URL + "/Public/Images/Membership/stredni_" + membership.photo} />
                                </div>
                                <div className="membership-desc">
                                    <h3 className="no-bottom-margin"><span>{membership.name}</span></h3>
                                    <div dangerouslySetInnerHTML={{__html:membership.description}}></div>
                                </div>
                            </div>
                            {membership.additionalServices && membership.additionalServices.length > 0 ?
                                <>
                                    <h3 className="text-center"><span>Bonuses</span></h3>
                                    <div className="bonuses">                               
                                        {
                                            membership.additionalServices.map((item,index)=>(
                                                <div className="bonus" key={item.additionalServiceID}>
                                                    <img alt={item.name} src={SERVER_URL + "/Public/Images/Services/" + item.photo} />
                                                    <p>{item.name}</p>
                                                </div>
                                            ))
                                        }
                                        
                                    </div>
                                </>
                            :null}

                            <div className="price-content border-panel">
                                <div className="inner-panel">
                                    <div className="price">
                                        <p><span>{t("Price")} {membership.additionalServices && membership.additionalServices.length > 0 ? t("IncludeBonuses") : ""}</span></p>
                                        <span>{helper.GetPriceString((membership.value + additionalPrice),i18n.language,currencyCode)}</span>
                                    </div>
                                </div>
                            </div>
                                
                            {data.customerContext.name == "" || data.customerContext.surname == "" || data.customerContext.street == "" || data.customerContext.zip == "" || data.customerContext.city == "" || data.customerContext.tel == "" || (data.customerContext.countryID == 239 && data.customerContext.state == "") ?
                                <div className="row">
                                    <div className="col col-6 col-sm-12">
                                        <div className="form-group">
                                            <div className="input">
                                                <label>{t("Name")}</label>
                                                <div>
                                                    <input type="text" name="name" className="form-control" value={formData.name} onChange={(e) => FormHandle(e)} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col col-6 col-sm-12">
                                        <div className="form-group">
                                            <div className="input">
                                                <label>{t("Surname")}</label>
                                                <div>
                                                    <input type="text" name="surname" className="form-control" value={formData.surname} onChange={(e) => FormHandle(e)} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col col-8 col-sm-12">
                                        <div className="form-group">
                                            <div className="input">
                                                <label>{t("Street")}</label>
                                                <div>
                                                    <input type="text" name="street" className="form-control" value={formData.street} onChange={(e) => FormHandle(e)} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col col-4 col-sm-12">
                                        <div className="form-group">
                                            <div className="input">
                                                <label>{t("ZIP")}</label>
                                                <div>
                                                    <input type="text" name="zip" className="form-control" value={formData.zip} onChange={(e) => FormHandle(e)} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col col-4 col-sm-12">
                                        <div className="form-group">
                                            <div className="input">
                                                <label>{t("City")}</label>
                                                <div>
                                                    <input type="text" name="city" className="form-control" value={formData.city} onChange={(e) => FormHandle(e)} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {formData.countryID == 239 ?
                                        <div className="col col-4 col-sm-12">
                                            <div className="form-group">
                                                <div className="input">
                                                    <label>{t("State")}</label>
                                                    <div>
                                                        <input type="text" name="state" className="form-control" value={formData.state} onChange={(e) => FormHandle(e)} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    :null}
                                    <div className="col col-4 col-sm-12">
                                        <div className="form-group">
                                            <div className="input">
                                                <label>{t("Country")}</label>
                                                <div>
                                                    <select className="form-control" name="countryID" value={formData.countryID} onChange={(e) => FormHandle(e)}>
                                                        {allCountries.map((item,index) => (
                                                            <option value={item.countryID} key={index}>{item.name}</option>
                                                        ))}
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col col-4 col-sm-12">
                                        <div className="form-group">
                                            <div className="input">
                                                <label>{t("Tel")}</label>
                                                <div>
                                                    <input type="text" name="tel" className="form-control payu-card-number" value={formData.tel} onChange={(e) => FormHandle(e)} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    
                                </div>
                            :null}

                            {!hasStoredPaymentMethod ? 
                                <div className="payment-button-container">
                                    <label>{t("YourCreditCardDetails")}</label>
                                    
                                    <CardElement options={CARD_ELEMENT_OPTIONS} />
                                    <button className="btn btn-primary btn-large btn-block pay-button" onClick={(e) => DoPayment(CardElement)} >{t("Pay")}</button>
                                </div>
                                
                            :
                                <button className="btn btn-primary btn-large btn-block" onClick={(e) => DoPayment()} >{t("Pay")}</button>                       
                            }

                            {errorMessage != "" &&
                                <Error text={errorMessage} />
                            } 

                        </div>

                    )
                )
            }

        </div>
    )
    
    
}

export default Payment;