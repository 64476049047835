/**
 * soubor se seznamem veškerých graphql dotazů pro změnu hesla
 */
import gql from 'graphql-tag';

export const CHANGE_PASSWORD = gql`
    mutation changePassword($oldPassword:String!,$password:String!,$lang:String!) {
        changePassword(oldPassword:$oldPassword,password:$password,lang:$lang)
    }
`;

export const SEND_FORGOT_PASS_EMAIL = gql`
mutation sendForgotPassEmail($email:String!,$lang:String) {
    sendForgotPassEmail(email:$email,lang:$lang)
}
`;

export const SAVE_NEW_PASSWORD = gql`
mutation saveNewPassword($passCode:String!,$password:String!,$lang:String) {
    saveNewPassword(passCode:$passCode,password:$password,lang:$lang)
}
`;